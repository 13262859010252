.container {
    gap: 16px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background-color: rgb(240, 240, 240);
}


@media (max-width: 600px) {
  .row {
    flex-direction: column !important;
    align-items: start !important;
  }

  .centered {
    align-self: start !important;
  }

  .radioFlexFlow {
    flex-direction: column !important;
  }

  .start, .end {
    justify-content: center !important;
  }
  
}

@media (max-width: 1100px) {
  .wrapTel {
    flex-direction: column !important;
    align-items: start !important;
    gap: 20px !important;
  }
  
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    gap: 64px;
}

.h1 {
    font-size: 30px;
    font-weight: 700;
    color: rgb(0, 0, 0);
    text-align: start;
}

.h2 {
    font-size: 24px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    text-align: start;
}

.card {
    padding: 1% 5% 1% 5%;
    width: 100%; 
}

.mb > div > div {
    margin-bottom: 24px;
}

.radioFlexFlow {
    display: "flex";
    flex-direction: "row";
}

.justifyStart {
    justify-content: start;
}

.mt0 {
    margin-top: 0;
}

.mt12 {
    margin-top: 12px;
}

.mb0 {
    margin-bottom: 0;
}

.centered {
    align-self: center;
}

.gap16 {
    gap: 16px;
}

.dropZone {
    border: 2px dashed rgb(0, 0, 0);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    font-weight: 400;
    text-align: center;
}

.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  border-top: 2px solid #444;
  background-color: #282c34;
  box-shadow: 0px -2px 10px #888;
  color: white;
  text-align: center;
}

.disconnect {
    align-self: center;
}

.spaceBetween {
    justify-content: space-between;
}

.flexGrow1 {
    flex-grow: 1;
}

.mb24 {
    margin-bottom: 24px;
}

.logoType {
    height: 60px;
    width: 60px;
    align-self: center;
    mix-blend-mode: multiply;
}

.logoHeader {
    height: 60px;
    mix-blend-mode: multiply;
    object-fit: contain;
}

.expanded {
    width: 100%;
    display: flex;
}

.end {
    justify-content: end;
}

.start {
    justify-content: start;
}

.italic {
    font-style: italic;
}