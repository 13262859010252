.card {
    width: 33%;
    min-width: 400px;
    max-width: 500px;
    height: 75%;
    min-height: 60px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 32px;
    border: rgb(31, 98, 127) 6px solid;
    border-radius: 16px;
}

.Form {
    gap: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 25%;
}

.Form>div {
    width: 75%;
    min-width: 200px;
}

.container {
    background-color: rgb(240, 240, 240);
    width: 100%;
    height: 100%;
    gap: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.h1 {
    font-size: 48px;
    text-align: center;
    margin: 0;
    margin-bottom: 12px;
}

.disconnect {
    align-self: end;
}

.smallText {
    font-size: 16px !important;
}
.container > img, .routeur > img {
    align-self: center;
    mix-blend-mode: multiply;
}

.calldispatchLogo {
    width: 20%;
    min-width: 400px;
    object-fit: contain;
}

.routeur {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.routeur > img {
    width: 200px;
    object-fit: contain;
}

.mtauto {
    margin-top: auto;
}   

.alignselfcenter {
    align-self: center;
}

.logo {
    align-self: center;
    mix-blend-mode: multiply;
    cursor: pointer;
}
