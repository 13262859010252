.container {
    background-color: rgb(240, 240, 240);
    width: 100%;
    height: 100%;
    gap: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card {
    width: 33%;
    min-width: 400px;
    max-width: 500px;
    height: 75%;
    min-height: 60px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 32px;
    border: rgb(31, 98, 127) 6px solid;
    border-radius: 16px;
}


.logo {
    align-self: center;
    mix-blend-mode: multiply;
    cursor: pointer;
}

.calldispatchLogo {
    width: 20%;
    min-width: 400px;
    object-fit: contain;
}

.dropContent {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.dropIcon {
    font-size: 300px;
    color: rgb(31, 98, 127);
    padding: 0;
    cursor: pointer;
}

.dropText {
    margin-top: 16px;
    font-family: 'Roboto', sans-serif;
}

/* Liste des OM */
.omListContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.omListTitle {
    font-family: 'Roboto', sans-serif;
    margin-bottom: 8px;
}

.omList {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.omItem {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    margin-bottom: 8px;
    padding: 4px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    transition: background-color 0.2s;
}

.omItem:hover {
    background-color: #cccccc;
}

/* Bouton reset (icône de suppression) */
.resetContainer {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: auto; /* Aligne le bouton en bas de la card */
}

.resetButton {
    background-color: transparent !important;
    color: rgb(127, 31, 31) !important;
    cursor: pointer;
}

.h1 {
    font-size: 36px;
    text-align: center;
    margin: 0;
    margin-bottom: 12px;
}