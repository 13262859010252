.card {
    width: 33%;
    min-width: 450px;
    height: 33%;
    min-height: 600px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: rgb(31, 98, 127) 6px solid;
    border-radius: 16px;
    
}

.blue {
    color: rgb(31, 98, 127);
}

.Form {
    gap: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Form > div {
    width: 75%;
    min-width: 200px;
}

.container {
    background-color: rgb(240, 240, 240);
    width: 100%;
    height: 100%;
    gap: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.h1 {
    font-size: 48px;
    text-align: center;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: start;
    gap: 64px;
}

.container > img {
    align-self: center;
    mix-blend-mode: multiply;
}

.calldispatchLogo {
    width: 20%;
    min-width: 400px;
    object-fit: contain;
}

.logo {
    align-self: center;
    mix-blend-mode: multiply;
    cursor: pointer;
}
