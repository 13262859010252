.container {
    background-color: rgb(240, 240, 240);
    width: 100%;
    min-height: 100vh;
    gap: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card {
    width: 33%;
    min-width: 400px;
    max-width: 500px;
    height: 75%;
    min-height: 60px;
    padding: 16px;
    padding-top: 28px;
    padding-bottom: 47px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    border: rgb(31, 98, 127) 6px solid;
    border-radius: 16px;
}

.h1 {
    font-size: 48px;
    text-align: center;
    margin: 0;
    margin-bottom: 12px;
}

/* Conteneur pour les options */
.optionsContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* Encadré de chaque option avec une marge verticale de 5% */
.optionBox {
    width: 70%;
    border: rgb(31, 98, 127) 4px solid;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10%;
}

/* Supprime la marge du dernier encadré */
.optionBox:last-child {
    margin-bottom: 0;
}

/* Style pour les libellés sous les icônes */
.iconLabel {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    margin-top: 8px;
}

.logo {
    align-self: center;
    mix-blend-mode: multiply;
    cursor: pointer;
}

.calldispatchLogo {
    width: 20%;
    min-width: 400px;
    object-fit: contain;
}
